import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Slider } from './ui/slider';
import { Upload, X } from 'lucide-react';
import { storage } from '@/config/firebase';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { useUserStore } from '@/stores/useUserStore';
import { functions } from '@/config/firebase';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface Point { x: number; y: number }
interface Area { width: number; height: number; x: number; y: number }

interface ImageUploaderProps {
  onImageUploaded: (urls: { full: string; thumbnail: string }) => void;
  currentImage?: string;
}

export const ImageUploader = ({ onImageUploaded, currentImage }: ImageUploaderProps) => {
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useUserStore();
  const { t } = useTranslation();

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
    size: { width: number; height: number }
  ): Promise<string> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('No 2d context');

    canvas.width = size.width;
    canvas.height = size.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      size.width,
      size.height
    );

    return canvas.toDataURL('image/jpeg', 0.9);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result as string);
        setIsDialogOpen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!image || !croppedAreaPixels || !user) return;

    try {
      setIsUploading(true);
      
      // Generate both sizes
      const fullSize = await getCroppedImg(image, croppedAreaPixels, { width: 400, height: 400 });
      const thumbnail = await getCroppedImg(image, croppedAreaPixels, { width: 150, height: 150 });

      // Upload both versions
      const fullSizeRef = ref(storage, `profile-photos/${user.uid}/full.jpg`);
      const thumbnailRef = ref(storage, `profile-photos/${user.uid}/thumbnail.jpg`);

      await uploadString(fullSizeRef, fullSize, 'data_url');
      await uploadString(thumbnailRef, thumbnail, 'data_url');

      // Get download URLs
      const fullUrl = await getDownloadURL(fullSizeRef);
      const thumbnailUrl = await getDownloadURL(thumbnailRef);

      await handleUploadSuccess({ full: fullUrl, thumbnail: thumbnailUrl });
      setIsDialogOpen(false);
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadSuccess = async (urls: { full: string; thumbnail: string }) => {
    try {
      // Update hasProfilePicture flag
      const updateProfilePictureStatus = httpsCallable(functions, 'updateProfilePictureStatus');
      await updateProfilePictureStatus({ hasProfilePicture: true });
      
      // Call the parent's callback
      onImageUploaded(urls);
    } catch (error) {
      console.error('Error updating profile picture status:', error);
      toast.error(t('profile.updatePictureError'));
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-4">
        {currentImage && (
          <img
            src={currentImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
          />
        )}
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-sm font-medium bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            <Upload className="h-4 w-4 mr-2" />
            Upload Photo
          </label>
        </div>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Crop Profile Photo</DialogTitle>
          </DialogHeader>
          <div className="relative h-[400px] w-full">
            {image && (
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            )}
          </div>
          <div className="px-4">
            <label className="text-sm font-medium">Zoom</label>
            <Slider
              value={[zoom]}
              min={1}
              max={3}
              step={0.1}
              onValueChange={([value]) => setZoom(value)}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => setIsDialogOpen(false)}
            >
              <X className="h-4 w-4 mr-2" />
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={isUploading}
            >
              {isUploading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}; 