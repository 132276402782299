import { storage } from '@/config/firebase';
import { getDownloadURL, ref } from 'firebase/storage';

export async function getProfilePhotoUrl(userId: string, type: 'full' | 'thumbnail'): Promise<string> {
  const filename = type === 'thumbnail' ? 'thumbnail.jpg' : 'full.jpg';
  const photoRef = ref(storage, `profile-photos/${userId}/${filename}`);
  try {
    return await getDownloadURL(photoRef);
  } catch (error) {
    console.error('Error getting profile photo URL:', error);
    throw error;
  }
} 