import { create } from 'zustand';
import { User } from 'firebase/auth';
import { auth, db } from '@/config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/config/firebase';

export interface ChainVerification {
  chainLinkNumber: number;
  verifiedAt: Date;
  stripePaymentId: string;
  bonuses?: {
    streak10?: boolean;
    streak50?: boolean;
    streak100?: boolean;
    oneOnOne?: boolean;
  };
}

export interface MemberData {
  memberNumber: number;
  timestamp: Date;
  city: string;
  country: string;
  allocationPreference: 'operational' | 'treasury';
}

export interface UserProfile {
  id: string;
  email: string;
  fullName: string;
  verified: boolean;
  memberNumber?: number;
  // Original location data
  city?: string;
  country?: string;
  // Display data
  displayName?: string;
  displayCity?: string;
  displayCountry?: string;
  bio?: string;
  links?: {
    linkedin?: string;
    twitter?: string;
    website?: string;
  };
  hasProfilePicture: boolean;
  stripeCustomerId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  // Public profile data
  publicProfile?: {
    bio?: string;
    links?: {
      linkedin?: string;
      twitter?: string;
      website?: string;
    };
    displayName?: string;
    displayCity?: string;
    displayCountry?: string;
    hasProfilePicture: boolean;
    memberNumber?: number;
    updatedAt?: Date;
  };
}

interface UserState {
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  initialized: boolean;
  setUser: (user: User | null) => void;
  setProfile: (profile: UserProfile | null) => void;
  setLoading: (loading: boolean) => void;
  initialize: () => void;
  refreshProfile: () => Promise<void>;
}

let authInitialized = false;

async function fetchUserProfile(uid: string): Promise<UserProfile | null> {
  try {
    console.log('Starting to fetch profile for uid:', uid);
    const userRef = doc(db, 'users', uid);
    const publicProfileRef = doc(db, 'publicProfiles', uid);
    
    // Fetch both documents in parallel
    const [userDoc, publicProfileDoc] = await Promise.all([
      getDoc(userRef),
      getDoc(publicProfileRef)
    ]);
    
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const publicData = publicProfileDoc.exists() ? publicProfileDoc.data() : {};
      
      return {
        id: uid,
        email: userData.email || '',
        fullName: userData.fullName || '',
        verified: userData.verified || false,
        memberNumber: userData.memberNumber,
        // Original location data
        city: userData.city,
        country: userData.country,
        // Display data
        displayName: userData.displayName,
        displayCity: userData.displayCity,
        displayCountry: userData.displayCountry,
        bio: userData.bio,
        links: userData.links || {},
        hasProfilePicture: userData.hasProfilePicture || false,
        stripeCustomerId: userData.stripeCustomerId,
        createdAt: userData.createdAt?.toDate(),
        updatedAt: userData.updatedAt?.toDate(),
        // Public profile data
        publicProfile: {
          bio: publicData.bio,
          links: publicData.links || {},
          displayName: publicData.displayName,
          displayCity: publicData.displayCity,
          displayCountry: publicData.displayCountry,
          hasProfilePicture: publicData.hasProfilePicture || false,
          memberNumber: publicData.memberNumber,
          updatedAt: publicData.updatedAt?.toDate()
        }
      };
    }
    console.log('No user document found');
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
}

export const useUserStore = create<UserState>((set, get) => ({
  user: null,
  profile: null,
  loading: true,
  initialized: false,

  setUser: (user) => set({ user }),
  setProfile: (profile) => set({ profile }),
  setLoading: (loading) => set({ loading }),

  refreshProfile: async () => {
    const { user } = get();
    if (user?.uid) {
      try {
        set({ loading: true });
        const profile = await fetchUserProfile(user.uid);
        set({ profile, loading: false });
      } catch (error) {
        console.error('Error refreshing profile:', error);
        set({ loading: false });
      }
    }
  },

  initialize: () => {
    if (authInitialized) return;
    authInitialized = true;

    console.log('Initializing auth state...');
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('Auth state changed:', user?.uid);
      set({ user, loading: true });

      if (user) {
        try {
          console.log('Fetching initial profile...');
          let profile = await fetchUserProfile(user.uid);
          
          // If no profile exists, try to create it
          if (!profile) {
            console.log('No profile found, creating new profile...');
            const createUserProfile = httpsCallable(functions, 'createUserProfile');
            await createUserProfile({
              email: user.email,
              fullName: user.displayName
            });
            // Fetch the newly created profile
            console.log('Fetching newly created profile...');
            profile = await fetchUserProfile(user.uid);
          }

          console.log('Setting profile:', profile);
          set({ 
            profile,
            loading: false,
            initialized: true
          });
        } catch (error) {
          console.error('Error initializing user profile:', error);
          set({ 
            profile: null,
            loading: false,
            initialized: true
          });
        }
      } else {
        console.log('No user, clearing profile');
        set({ 
          profile: null,
          loading: false,
          initialized: true
        });
      }
    });

    return () => unsubscribe();
  }
}));

// Initialize the store
useUserStore.getState().initialize(); 