import { useEffect, useState } from 'react';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/config/firebase';
import { CountryFlag } from '@/components/CountryFlag';
import { formatDistanceToNow } from 'date-fns';
import { Skeleton } from '@/components/ui/skeleton';
import { MemberPublicData, MovementStats } from '@/types/movement';
import { Card } from '@/components/ui/card';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getProfilePhotoUrl } from '@/utils/storage';
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Linkedin, Twitter, Globe } from 'lucide-react';
import { UserProfile } from '@/stores/useUserStore';
import { UserPublicProfile } from '@/types/movement';

interface FirestoreMemberData extends Omit<MemberPublicData, 'timestamp'> {
  timestamp: Timestamp;
}

interface MembersListDocument {
  members: FirestoreMemberData[];
  updateTimestamp: Timestamp;
}

export function MembersList({ limit: memberLimit = 10 }) {
  const [members, setMembers] = useState<MemberPublicData[]>([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<MovementStats | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  const [memberPhotos, setMemberPhotos] = useState<Record<string, string>>({});
  const [selectedMember, setSelectedMember] = useState<MemberPublicData | null>(null);
  const [selectedMemberProfile, setSelectedMemberProfile] = useState<UserPublicProfile | null>(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Fetch stats
        const statsDoc = await getDoc(doc(db, 'movement/stats'));
        if (statsDoc.exists()) {
          const statsData = statsDoc.data() as MovementStats & { lastUpdateTimestamp: Timestamp };
          setStats({
            totalMembers: statsData.totalMembers || 0,
            totalCountries: statsData.totalCountries || [],
            operationalFundsCollected: statsData.operationalFundsCollected || 0,
            treasuryFundsCollected: statsData.treasuryFundsCollected || 0,
            lastMemberNumber: statsData.lastMemberNumber || 0,
            lastUpdateTimestamp: statsData.lastUpdateTimestamp?.toDate() || new Date(),
          });
        }

        // Calculate which document to fetch based on total members
        const totalMembers = statsDoc.exists() ? statsDoc.data().totalMembers || 0 : 0;
        const currentListNumber = Math.floor((totalMembers - 1) / 1000);
        const listNumberStr = currentListNumber.toString().padStart(4, '0');
        
        // Updated path for members document
        const membersDoc = await getDoc(doc(db, 'members', listNumberStr));
        
        if (membersDoc.exists()) {
          const data = membersDoc.data() as MembersListDocument;
          if (data?.members && Array.isArray(data.members)) {
            const convertedMembers = data.members
              .map((member: FirestoreMemberData) => ({
                ...member,
                timestamp: member.timestamp.toDate(),
              }))
              .slice(-memberLimit)
              .reverse();
            
            setMembers(convertedMembers);
          }
        }
      } catch (error) {
        console.error('Error fetching members:', error);
        setError(error instanceof Error ? error : new Error('Failed to fetch members'));
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [memberLimit]);

  useEffect(() => {
    const loadProfilePhotos = async () => {
      const photoUrls: Record<string, string> = {};
      for (const member of members) {
        if (member.userId && member.hasProfilePicture) {
          try {
            photoUrls[member.userId] = await getProfilePhotoUrl(member.userId, 'thumbnail');
          } catch (error) {
            console.error('Error loading profile photo:', error);
          }
        }
      }
      setMemberPhotos(photoUrls);
    };

    if (members.length > 0) {
      loadProfilePhotos();
    }
  }, [members]);

  const handleMemberClick = async (member: MemberPublicData) => {
    setSelectedMember(member);
    if (member.userId) {
      try {
        const publicProfileRef = doc(db, 'publicProfiles', member.userId);
        const publicProfileDoc = await getDoc(publicProfileRef);
        if (publicProfileDoc.exists()) {
          const profileData = publicProfileDoc.data() as UserPublicProfile;
          setSelectedMemberProfile(profileData);
        }
      } catch (error) {
        console.error('Error fetching member data:', error);
      }
    }
  };

  if (error) {
    return (
      <div className="text-center text-red-500">
        <p>Failed to load members. Please try again later.</p>
      </div>
    );
  }

  if (loading) {
    return <MembersListSkeleton count={memberLimit} />;
  }

  return (
    <div className="space-y-8">
      {stats && (
        <div className="grid grid-cols-2 gap-6">
          <Card className="p-6 text-center">
            <div className="text-4xl font-bold text-primary mb-2">
              {stats.totalMembers.toLocaleString()}
            </div>
            <div className="text-sm font-medium text-muted-foreground">
              {t('members.stats.membersLabel')}
            </div>
          </Card>
          <Card className="p-6 text-center">
            <div className="text-4xl font-bold text-primary mb-2">
              {stats.totalCountries.length.toLocaleString()}
            </div>
            <div className="text-sm font-medium text-muted-foreground">
              {t('members.stats.countriesLabel')}
            </div>
          </Card>
        </div>
      )}

      <div className="space-y-3">
        {members.map((member) => (
          <div key={member.memberNumber} onClick={() => handleMemberClick(member)}>
            <Card className="p-4 hover:bg-accent/50 transition-colors cursor-pointer">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-[58px]">
                    {member.userId && member.hasProfilePicture ? (
                      <img
                        src={memberPhotos[member.userId] || '/images/default-avatar.png'}
                        alt={member.displayName}
                        className="w-[38px] h-[38px] rounded-full object-cover bg-muted"
                        onError={(e) => {
                          const img = e.target as HTMLImageElement;
                          img.src = '/images/default-avatar.png';
                        }}
                      />
                    ) : member.userId ? (
                      <div className="w-[38px] h-[38px] rounded-full bg-muted flex items-center justify-center">
                        <span className="text-xl text-muted-foreground">
                          {member.displayName?.[0] || '#'}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col min-w-0">
                    <div className="flex items-center gap-2">
                      <span className="font-medium truncate">
                        {member.displayName || `Member #${member.memberNumber}`}
                      </span>
                      {member.displayName && (
                        <span className="text-sm text-muted-foreground shrink-0">
                          #{member.memberNumber}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <CountryFlag countryId={member.displayCountry || ''} />
                      {(member.displayCity || member.displayCountry) && (
                        <span className="truncate">
                          {[member.displayCity, member.displayCountry]
                            .filter(Boolean)
                            .join(', ')}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-sm text-muted-foreground shrink-0">
                  {formatDistanceToNow(member.timestamp, { addSuffix: true })}
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>

      <Dialog open={!!selectedMember} onOpenChange={() => {
        setSelectedMember(null);
        setSelectedMemberProfile(null);
      }}>
        <DialogContent className="sm:max-w-2xl">
          {selectedMember && (
            <div className="p-6">
              <div className="flex items-start gap-6">
                <div className="w-20 h-20 shrink-0">
                  {selectedMember.userId && selectedMember.hasProfilePicture ? (
                    <img
                      src={memberPhotos[selectedMember.userId] || '/images/default-avatar.png'}
                      alt={selectedMember.displayName}
                      className="w-full h-full rounded-full object-cover bg-muted"
                      onError={(e) => {
                        const img = e.target as HTMLImageElement;
                        img.src = '/images/default-avatar.png';
                      }}
                    />
                  ) : (
                    <div className="w-full h-full rounded-full bg-muted flex items-center justify-center">
                      <span className="text-2xl text-muted-foreground">
                        {selectedMember.displayName?.[0] || '#'}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2 mb-2">
                    <h2 className="text-2xl font-bold truncate">
                      {selectedMember.displayName || `Member #${selectedMember.memberNumber}`}
                    </h2>
                    <span className="text-muted-foreground shrink-0">
                      #{selectedMember.memberNumber}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-muted-foreground">
                    <CountryFlag countryId={selectedMember.displayCountry || ''} />
                    {(selectedMember.displayCity || selectedMember.displayCountry) && (
                      <span className="truncate">
                        {[selectedMember.displayCity, selectedMember.displayCountry]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              
              {selectedMemberProfile?.bio && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold mb-2">{t('profile.about')}</h3>
                  <p className="text-muted-foreground whitespace-pre-wrap">
                    {selectedMemberProfile.bio}
                  </p>
                </div>
              )}
              
              {selectedMemberProfile?.links && 
               Object.values(selectedMemberProfile.links).some(Boolean) && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold mb-3">{t('profile.links')}</h3>
                  <div className="flex flex-wrap gap-3">
                    {selectedMemberProfile.links.linkedin && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => window.open(selectedMemberProfile.links?.linkedin, '_blank')}
                      >
                        <Linkedin className="w-4 h-4 mr-2" />
                        LinkedIn
                      </Button>
                    )}
                    {selectedMemberProfile.links.twitter && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => window.open(selectedMemberProfile.links?.twitter, '_blank')}
                      >
                        <Twitter className="w-4 h-4 mr-2" />
                        X (Twitter)
                      </Button>
                    )}
                    {selectedMemberProfile.links.website && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => window.open(selectedMemberProfile.links?.website, '_blank')}
                      >
                        <Globe className="w-4 h-4 mr-2" />
                        Website
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

function MembersListSkeleton({ count }: { count: number }) {
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-6">
        <Card className="p-6">
          <Skeleton className="h-10 w-full mb-2" />
          <Skeleton className="h-4 w-2/3 mx-auto" />
        </Card>
        <Card className="p-6">
          <Skeleton className="h-10 w-full mb-2" />
          <Skeleton className="h-4 w-2/3 mx-auto" />
        </Card>
      </div>
      <div className="space-y-3">
        {Array.from({ length: count }).map((_, i) => (
          <Card key={i} className="p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-6">
                <Skeleton className="h-4 w-12" />
                <Skeleton className="h-4 w-32" />
              </div>
              <Skeleton className="h-4 w-24" />
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
} 