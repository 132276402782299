import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from './ui/button';
import { ThemeToggle } from './ThemeToggle';
import { Menu, User, LogOut, Settings, User2 } from 'lucide-react';
import { cn } from '../lib/utils';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '@/stores/useUserStore';
import { authService } from '@/services/auth.service';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from '@/components/ui/dropdown-menu';
import { useStripePayment } from '@/hooks/useStripePayment';

export function HeaderNavigation() {
  const { user, loading } = useUserStore();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleVerificationPayment, loading: stripeLoading } = useStripePayment();

  // Memoize handlers
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setIsScrolled(currentScrollPos > 10);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  useEffect(() => {
    // Throttle scroll event
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, [handleScroll]);

  const handleNavClick = useCallback(() => {
    window.scrollTo(0, 0);
    setIsMobileMenuOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await authService.logout();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, [navigate]);

  const isActivePath = useCallback((path: string) => {
    return location.pathname === path;
  }, [location.pathname]);

  const handleStripeCheckout = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!user) {
      // If no user, redirect to signup page
      navigate('/signup');
      return;
    }

    try {
      await handleVerificationPayment(user.email!, {
        userId: user.uid,
        email: user.email!,
        fullName: user.displayName || undefined,
        coverOperationalCosts: true
      });
    } catch (error) {
      console.error('Checkout failed:', error);
      // Optional: Add toast notification here
      // toast.error('Payment initialization failed. Please try again.');
    }
  };

  return (
    <header 
      style={{
        backdropFilter: isScrolled ? 'blur(20px)' : 'none',
        WebkitBackdropFilter: isScrolled ? 'blur(20px)' : 'none'
      }}
      className={cn(
        'fixed top-0 left-0 right-0 z-50 transition-all duration-300',
        isScrolled 
          ? 'bg-white/60 dark:bg-gray-950/60 h-[60px] shadow-sm' 
          : 'bg-transparent h-[80px]',
        isVisible 
          ? 'translate-y-0' 
          : '-translate-y-full'
      )}
    >
      <div className="container mx-auto h-full px-8 flex justify-between items-center">
        <Link to="/" className="flex items-center" onClick={handleNavClick}>
          <div 
            className="logo bg-contain bg-no-repeat bg-center h-12 w-24"
            aria-label="Ctrl AI Logo" 
          />
        </Link>
        
        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-4 h-full">
          <nav className="flex items-center space-x-6 mr-4 h-full">
            
            
            
          
            <Link 
              to="/ownership" 
              className={cn(
                "hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full",
                isActivePath('/ownership') 
                  ? "text-blue-500 dark:text-blue-400" 
                  : "text-gray-700 dark:text-gray-200"
              )}
              onClick={handleNavClick}
            >
              {t('nav.ownership')}
            </Link>
            <Link 
              to="/founders" 
              className={cn(
                "hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full",
                isActivePath('/founders') 
                  ? "text-blue-500 dark:text-blue-400" 
                  : "text-gray-700 dark:text-gray-200"
              )}
              onClick={handleNavClick}
            >
              {t('nav.founders')}
            </Link>
            <Link 
              to="/constitution" 
              className={cn(
                "hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full",
                isActivePath('/constitution') 
                  ? "text-blue-500 dark:text-blue-400" 
                  : "text-gray-700 dark:text-gray-200"
              )}
              onClick={handleNavClick}
            >
              {t('nav.constitution')}
            </Link>
           {/*  <Link 
              to="/members" 
              className={cn(
                "hover:text-blue-500 dark:hover:text-blue-400 flex items-center h-full",
                isActivePath('/members') 
                  ? "text-blue-500 dark:text-blue-400" 
                  : "text-gray-700 dark:text-gray-200"
              )}
              onClick={handleNavClick}
            >
              {t('nav.members')}
            </Link> */}
          </nav>

          <div className="flex items-center space-x-4">
            {!loading && (
              <>
                {user ? (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="flex items-center gap-2">
                        <User className="h-4 w-4" />
                        {user.email || 'Menu'}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem asChild>
                        <Link to={`/profile/edit`} className="flex items-center">
                          <Settings className="mr-2 h-4 w-4" />
                          {t('nav.editProfile')}
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuItem asChild>
                        <Link to={`/profile/${user.uid}`} className="flex items-center">
                          <User2 className="mr-2 h-4 w-4" />
                          {t('nav.viewProfile')}
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={handleLogout}>
                        <LogOut className="mr-2 h-4 w-4" />
                        {t('nav.signOut')}
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                ) : (
                  <>
                    <Link to="/login">
                      <Button variant="ghost">Login</Button>
                    </Link>
                    <Link to="/signup">
                      <Button 
                        variant="default" 
                        className="bg-blue-500 hover:bg-blue-600 text-white"
                      >
                        Get Started
                      </Button>
                    </Link>
                  </>
                )}
              </>
            )}
            
            <LanguageSwitcher />
            <ThemeToggle />
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden p-2 flex items-center"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <Menu className="h-5 w-5" />
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className={cn(
          "md:hidden fixed left-0 right-0 w-full",
          "bg-white/95 dark:bg-gray-950/95",
          isScrolled ? "top-[60px]" : "top-[80px]"
        )}>
          <nav className="flex flex-col space-y-4 px-8 py-4">
            <div className="flex flex-col space-y-4 items-end">
            
              
              <Link 
                to="/founders" 
                className={cn(
                  "hover:text-blue-500 dark:hover:text-blue-400 text-right w-full",
                  isActivePath('/founders') 
                    ? "text-blue-500 dark:text-blue-400" 
                    : "text-gray-700 dark:text-gray-200"
                )}
                onClick={handleNavClick}
              >
                {t('nav.founders')}
              </Link>
              <Link 
                to="/ownership" 
                className={cn(
                  "hover:text-blue-500 dark:hover:text-blue-400 text-right w-full",
                  isActivePath('/ownership') 
                    ? "text-blue-500 dark:text-blue-400" 
                    : "text-gray-700 dark:text-gray-200"
                )}
                onClick={handleNavClick}
              >
                {t('nav.ownership')}
              </Link>
              <Link 
                to="/constitution" 
                className={cn(
                  "hover:text-blue-500 dark:hover:text-blue-400 text-right w-full",
                  isActivePath('/constitution') 
                    ? "text-blue-500 dark:text-blue-400" 
                    : "text-gray-700 dark:text-gray-200"
                )}
                onClick={handleNavClick}
              >
                {t('nav.constitution')}
              </Link>
            {/*   <Link 
                to="/members" 
                className={cn(
                  "hover:text-blue-500 dark:hover:text-blue-400 text-right w-full",
                  isActivePath('/members') 
                    ? "text-blue-500 dark:text-blue-400" 
                    : "text-gray-700 dark:text-gray-200"
                )}
                onClick={handleNavClick}
              >
                {t('nav.members')}
              </Link> */}
              {user ? (
                <>
                  <button
                    onClick={handleLogout}
                    className="flex items-center justify-end gap-2 w-full"
                  >
                    Sign Out
                    <LogOut className="h-4 w-4" />
                  </button>
                </>
              ) : (
                <>
                  <Link 
                    to="/login" 
                    className="w-full text-right"
                    onClick={handleNavClick}
                  >
                    Login
                  </Link>
                  <Link 
                    to="/signup" 
                    className="w-full text-right bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                    onClick={handleNavClick}
                  >
                    Get Started
                  </Link>
                </>
              )}
            </div>

            <div className="flex items-center space-x-4 justify-end pt-4">
              <LanguageSwitcher />
              <ThemeToggle />
            </div>
          </nav>
        </div>
      )}
    </header>
  );
}

// Memoize the entire component
export default React.memo(HeaderNavigation);
