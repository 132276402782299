export const en = {

  hero: {
    title: "We Can't Fully Control AI—But We Must Try",
    subtitle: 
      "Join Ctrl AI, a nonprofit movement where every human has an equal vote in shaping AI's future. Verify your humanity with $1 and become a co-owner of a global effort to guide AI ethically and transparently.",
    chainCall: "Every voice matters. Add yours to the movement."
  },

  problem: {
    category: "The Challenge",
    title: "AI's power is concentrated in the hands of a few—what if they don't choose what's best for humanity?",
    subtitle: "We must ensure AI is aligned with all of humanity—not just those with power and money.",
    citation:
      "This is what we call 'AI Colonialism.' And the only way to stop it is by uniting billions of everyday people in true AI ownership.",
    pointsTitle: "Why You Must Act Now:",
    points: [
      {
        headline: "Big Tech Monopolies",
        text: "A handful of corporations hold the data, resources, and AI breakthroughs."
      },
      {
        headline: "Limited Accountability",
        text: "Corporate profit motives can overshadow the public good."
      },
      {
        headline: "Societal Upheaval",
        text: "As AI outperforms humans in more jobs, society must be prepared."
      }
    ],
    conclusion: "Either we co-own tomorrow's AI—or we leave it to the mega-corporations that profit off our data."
  },

  opportunity: {
    title: "Our Solution—A Human Movement",
    subtitle: "Every verified member is an equal partner—no more, no less.",
    benefitsTitle: "Why This Matters",
    benefits: [
      {
        headline: "Nonprofit & 100% User-Owned",
        text: "Your $1 isn't a fee—it's proof you're human. Members decide how these funds are used."
      },
      {
        headline: "Open Governance",
        text: "Each person has exactly one vote—no extra power for early joiners."
      },
      {
        headline: "Real Impact",
        text: "By uniting, we create a powerful coalition that Big Tech and governments must respect."
      }
    ]
  },

  howItWorks: {
    title: "How It Works",
    points: [
      {
        title: "1. Verify You're Human ($1)",
        bullets: [
          "A simple check to block bots and ensure fairness.",
          "Decide whether your $1 goes to community treasury or covers operational costs."
        ]
      },
      {
        title: "2. Become a Co-Owner",
        bullets: [
          "One-person, one-vote on key decisions—no matter when you join."
        ]
      },
      {
        title: "3. Spread the Word",
        bullets: [
          "Our movement grows only by real humans telling others—no ads, no investors."
        ]
      }
    ]
  },


  commitment: {
    title: "Our Commitment",
    subtitle: "Building a foundation of trust and transparency",
    points: [
      {
        headline: "Full Transparency",
        text: "We publicly share our finances, decisions, and progress."
      },
      {
        headline: "Nonprofit Forever",
        text: "No corporate shareholders—everyone has a stake."
      },
      {
        headline: "Ethical AI Development",
        text: "We build and support AI that prioritizes human well-being and social good."
      }
    ]
  },

  shareMessage: {
    title: "Help Us Grow—No Ads, No Investors, Just People",
    subtitle: 
      "Ctrl AI thrives on real human voices sharing our message. Think of it as a global pay-it-forward—our collective future depends on each person who joins. After you join, please invite a friend.",
    cta: "Share Ctrl AI"
  },

  cta: {
    title: "Ready to shape AI's future?",
    subtitle: 
      "Verify your humanity for $1 and stand alongside others demanding ethical, inclusive AI. Together, we can ensure a future where AI works for everyone.",
    buttons: {
      primary: "Join Now ($1)",
      secondary: "Members List"
    }
  },

  aspiringFoundersPage: {
    hero: {
      category: "Creators & Innovators",
      title: "Build with Ctrl AI—Shape the Future of Tech",
      subtitle:
        "Got a vision for an ethical, user-focused AI app or open-source model? Pitch it to the Ctrl AI community and work together to bring it to life—free from ads, VCs, or hidden agendas. All subject to our members' collective approval.",
    },
  
    intro: {
      title: "Our Philosophy: People Over Profits",
      subtitle:
        "As open-source AI models rapidly catch up to corporate offerings, there's a unique window for builders to create better, more human-centered solutions. At Ctrl AI, we believe innovation thrives when:\n• It's collectively funded by people who'll actually use it.\n• Founders aren't forced to chase profit at users' expense.\n• Technology remains open, transparent, and community-driven.",
      pointsTitle: "Why Build Under Ctrl AI?",
      points: [
        {
          headline: "Community-First Funding",
          text: "No predatory venture capital or ad-driven constraints. Our nonprofit, user-owned community can directly fund your project if they believe in it."
        },
        {
          headline: "Open Source Momentum",
          text: "Leverage open-source AI models that keep pace with corporate giants. Enhance them together with our global membership."
        },
        {
          headline: "A New Social Contract",
          text: "With thousands (eventually millions) of co-owners, you have a built-in audience invested in your project's success—and in keeping it ethical."
        }
      ],
      disclaimer:
        "All details below are initial concepts and will ultimately be shaped by the collective voice of Ctrl AI members."
    },
  
    ownershipModels: {
      title: "Flexible Ownership Structures",
      subtitle:
        "We recognize that different projects have different needs. Below are our initial ideas for how founder teams and the community can share ownership and responsibilities.",
      models: [
        {
          headline: "Smaller Projects: Founder Share Up to 5%",
          text: "Founders retain a small stake while the rest is owned by the community. The community may also vote to provide founders with a monthly salary, letting them focus on building full-time."
        },
        {
          headline: "Larger Ambitious Projects: Fully Community-Owned",
          text: "If the community can raise enough funds to cover development, infrastructure, and staffing, it can opt for 100% collective ownership—offering fair compensation for contributors without long-term founder equity."
        }
      ],
      note:
        "Exactly how each project's ownership is structured—and how funds are allocated—will be determined through open discussion and a democratic vote once we reach a critical mass of members."
    },
  
    pitchProcess: {
      category: "Pitch & Approval",
      title: "How You Can Launch a Project",
      subtitle:
        "Once our community is large enough to support multiple initiatives, we'll invite founders to pitch their ideas. The community collectively decides which projects to fund, build, and prioritize.",
      stepsTitle: "Initial Steps (Subject to Community Ratification)",
      steps: [
        {
          headline: "1. Pitch Your Vision",
          text: "Prepare a brief proposal outlining your idea, its potential impact, and any team members."
        },
        {
          headline: "2. Open Discussion",
          text: "All Ctrl AI members weigh in—asking questions, suggesting improvements, and gauging community interest."
        },
        {
          headline: "3. Community Voting",
          text: "If your project garners enough support, you'll receive funding and resources to move forward. Founders might draw a monthly salary during development, as approved by members."
        },
        {
          headline: "4. Ongoing Transparency",
          text: "Regular progress updates, open-source code where feasible, and a commitment to user well-being are required throughout development."
        }
      ],
      conclusion:
        "No one-size-fits-all approach, no red tape—just a people-powered platform where the best, most ethical ideas can thrive."
    },
  
    buildingInPublic: {
      category: "Open Collaboration",
      title: "Build in Public, Grow Together",
      subtitle:
        "Transparency is at the core of Ctrl AI's DNA. By sharing your roadmap, challenges, and milestones with co-owners, you invite genuine collaboration—and earn lasting support.",
      pointsTitle: "Our Public-Building Principles",
      points: [
        {
          headline: "Open Roadmaps",
          text: "Keep the community updated on key features, timelines, and long-term goals."
        },
        {
          headline: "Collective Testing & Feedback",
          text: "Tap into a global user base for beta testing, bug spotting, and refinement."
        },
        {
          headline: "Frequent Progress Reports",
          text: "Share wins, hurdles, and upcoming tasks weekly or monthly to maintain trust and momentum."
        }
      ],
      conclusion:
        "This open approach not only helps craft better solutions—it keeps everyone accountable to the human-centric values we stand for."
    },
  
    callToAction: {
      category: "Get Involved",
      title: "Ready to Bring Your Idea to Life?",
      subtitle:
        "We're in the early stages of building a global movement. Once we reach enough members, we'll launch the pitching process. Join Ctrl AI now, start connecting with fellow co-owners, and refine your vision so you're ready when the time comes.",
      stepsTitle: "Next Steps (For Now)",
      steps: [
        {
          headline: "1. Join Ctrl AI",
          text: "Become a verified member for $1. Your vote and voice will shape how we select and fund new projects."
        },
        {
          headline: "2. Engage with the Community",
          text: "Get to know potential collaborators and future users. Discuss your concept informally to build support early."
        },
        {
          headline: "3. Prepare Your Pitch",
          text: "Outline your idea, including how it aligns with Ctrl AI's values and any budget needs. Stay tuned for pitch submissions."
        }
      ],
      conclusion:
        "**These frameworks are not set in stone.** Together, we'll refine how we support visionary founders while keeping technology truly user-centered."
    }
  },

  newSocialContractPage: {
    hero: {
      category: "A New Social Contract for the AI Age",
      title: "Join Ctrl AI: A Nonprofit, People-Powered Movement",
      subtitle:
        "By verifying your humanity with $1, you become a co-owner of our global effort to keep AI ethical, transparent, and inclusive. No shareholders, no profit-hungry investors—just one-person, one-vote.",
    },
  
    intro: {
      title: "Why a Nonprofit, User-Owned Model?",
      subtitle:
        "We believe that in the face of fast-moving AI breakthroughs, people—not big corporations—must decide how technology evolves. Ctrl AI is building a new social contract: one that puts human well-being and community governance at the core.",
      pointsTitle: "Key Principles",
      points: [
        {
          headline: "One-Person, One-Vote",
          text: "Regardless of when you join or how much money you have, your voice counts equally—just like the founder's."
        },
        {
          headline: "No Ads, No Investors",
          text: "We rely on a one-time $1 verification to keep out bots and ensure true human ownership, free from corporate agendas."
        },
        {
          headline: "Global Inclusivity",
          text: "Anyone, anywhere can join. Our goal is to bring together voices from every corner of the world."
        }
      ],
      conclusion:
        "When everyday people co-own AI, we ensure it's steered by shared values—fairness, transparency, and empowerment—not just profit."
    },
  
    newOwnershipModel: {
      title: "Rewriting the Rules of Ownership",
      subtitle:
        "Traditional tech companies answer to investors and ad revenue. Ctrl AI answers to its users—because users are the owners.",
      pointsTitle: "How We're Different",
      points: [
        {
          headline: "100% Community-Led",
          text: "We're a nonprofit with no outside shareholders. All members collectively shape our roadmap and have the final say."
        },
        {
          headline: "Transparent Funding & Spending",
          text: "Your $1 contribution is fully accounted for. Our finances are publicly visible, and any surplus funds are allocated by member vote."
        },
        {
          headline: "Shared Responsibility",
          text: "Because we co-own the platform, we also share responsibility for making it ethical, useful, and aligned with humanity's needs."
        }
      ],
      note:
        "This is more than a business model—it's a new social contract where technology is accountable to all who use it."
    },
  
    whyJoinNow: {
      title: "Why Become a Founding Member?",
      subtitle:
        "We're at a tipping point: AI is moving so quickly that a handful of corporations could dominate it—and decide how we all live and work. By joining now, you become an early co-architect of a different future.",
      points: [
        {
          headline: "Shape Our Constitution",
          text: "As part of the founding community, you'll help finalize our guiding principles and keep leadership accountable."
        },
        {
          headline: "Direct Influence",
          text: "Propose or vote on critical decisions, from budget allocations to which AI tools we build first."
        },
        {
          headline: "Set a Global Example",
          text: "Help prove that technology can be developed ethically and transparently when people, not profits, are in charge."
        }
      ],
      conclusion:
        "Joining now means standing at the forefront of a people-powered AI revolution—one that prioritizes collective well-being over corporate interests."
    },
  
    howItWorks: {
      title: "Joining Is Simple",
      steps: [
        {
          title: "1. Verify Your Humanity",
          text: "Contribute a one-time $1 fee to confirm you're a real person. No extra charges—no subscriptions."
        },
        {
          title: "2. Become a Co-Owner",
          text: "Your membership grants you an equal vote on every major decision, from our constitution to project funding."
        },
        {
          title: "3. Spread the Word",
          text: "We grow through human connections, not ads or investor money. Invite others so our collective voice grows stronger."
        }
      ]
    },
  
    finalCall: {
      title: "Ready to Help Rewrite the Future of AI?",
      subtitle:
        "Add your voice, your ideas, and your passion. Together, we can ensure AI remains a tool for all of humanity—not just the elite.",
      button: "Join Ctrl AI"
    }
  },

  constitutionPage: {
    hero: {
      title: "The Ctrl AI Constitution (Draft)",
      preamble:
        "In an age where technology increasingly shapes humanity's future, we choose a different path: not just building better products, but building with better principles. This is our first draft—once we reach a critical mass of members worldwide, we'll refine it together through a community vote."
    },
  
    principles: {
      title: "Our Core Commitments",
      intro:
        "These are the foundational values that guide Ctrl AI. While they may evolve, the essence remains: prioritize human well-being, transparency, and community oversight in all AI development. Once ratified by the global membership, these commitments become binding for every project under Ctrl AI:",
  
      principles: [
        {
          title: "Human Well-being First",
          items: [
            "Design for genuine human flourishing, not obsessive engagement metrics",
            "Zero tolerance for manipulative design or psychological exploitation",
            "Respect user attention as a limited, precious resource",
            "Encourage healthy usage patterns and natural stopping points",
            "Foster meaningful connections over addictive experiences"
          ]
        },
        {
          title: "Absolute Data Rights",
          items: [
            "Users fully own their data—no selling or unauthorized sharing",
            "Use local-first data processing wherever possible",
            "Employ strong encryption as a default standard",
            "Guarantee the right to be fully forgotten at any time"
          ]
        },
        {
          title: "Real Community Power",
          items: [
            "100% community ownership—no corporate shareholders",
            "One-person, one-vote on major decisions (including the founder)",
            "Transparent budgeting and open governance processes",
            "Global inclusivity—everyone is welcome to join with a simple $1 human verification"
          ]
        },
        {
          title: "Ethical AI Boundaries",
          items: [
            "All AI must serve human agency—never subvert or override it",
            "Maintain transparency in AI logic and decision processes",
            "Continuously monitor and mitigate bias",
            "Always allow for a clear human override on AI features",
            "Focus development on tools that empower rather than replace human potential"
          ]
        },
        {
          title: "Team Well-being",
          items: [
            "Provide comprehensive mental health support for all contributors",
            "Encourage a balanced workload—no chronic 'crunch'",
            "Fair and transparent compensation for dedicated staff",
            "Remote-friendly, with optional collaboration spaces",
            "Regular team and community connection events"
          ]
        },
        {
          title: "Education First",
          items: [
            "Build educational value into all products wherever possible",
            "Ensure age-appropriate design, especially for younger users",
            "Promote digital literacy and responsible tech usage",
            "Prohibit manipulative practices that exploit youth"
          ]
        },
        {
          title: "Radical Transparency",
          items: [
            "Frequent public updates on platform progress and finances",
            "Open-source code by default to foster trust and community input",
            "Directly communicate challenges, setbacks, and changes",
            "Hold regular community Q&A or engagement sessions"
          ]
        },
        {
          title: "Sustainable Development",
          items: [
            "Grow organically through real human connections, not aggressive marketing",
            "Evaluate the environmental impact of all decisions",
            "Prioritize long-term resilience over short-term gains",
            "Avoid exploitative or misleading tactics to increase user numbers"
          ]
        },
        {
          title: "Fair Revenue",
          items: [
            "No ad-based revenue models that incentivize data exploitation",
            "Offer transparent pricing or donations when costs arise",
            "Ensure clear communication of cost and benefits to users",
            "Any revenue directly supports community initiatives or platform improvements"
          ]
        },
        {
          title: "Innovation With Purpose",
          items: [
            "Focus on real human and societal needs",
            "Encourage open collaboration and shared knowledge",
            "Apply technology in service of human dignity and empowerment",
            "Prioritize social impact and community benefit in every decision"
          ]
        }
      ]
    },
  
    enforcement: {
      title: "Real Enforcement, Real Accountability",
      content:
        "These principles aren't just lofty ideals; they're guiding rules with mechanisms for correction if we fall short. Our community has the final say in keeping Ctrl AI accountable.",
      measures: [
        {
          title: "Community Oversight",
          items: [
            "• Scheduled public audits of code, finances, and processes",
            "• Real-time reporting of potential violations",
            "• Direct membership feedback channels",
            "• Independent ethical review board for neutral investigations"
          ]
        },
        {
          title: "Clear Consequences",
          items: [
            "• Prompt response to issues raised by members",
            "• Community-driven solutions and course corrections",
            "• Mandatory remediation steps when breaches occur",
            "• Transparent reporting on how each violation is resolved"
          ]
        },
        {
          title: "Living Document",
          items: [
            "• Annual review to adapt to new technologies or needs",
            "• Core principles remain immutable except by community supermajority",
            "• Democratic amendment process once critical mass is reached",
            "• Open invitations for member suggestions"
          ]
        }
      ]
    },
  
    cta: {
      title: "Join Us in Writing the Future of AI",
      content:
        "This draft Constitution is just the beginning. Once we have a diverse, global membership, our community will finalize and ratify these principles together. Whether you're a developer, policymaker, or everyday user, your voice has equal power here. Ready to take part?",
      button: "Join Ctrl AI for $1"
    }
  },

  footer: {
    companyDescription: "Building the future of AI with community ownership and ethical principles.",
    navigation: "Navigation",
    legal: "Legal",
    connect: "Other",
    privacy: "Privacy Policy",
    terms: "Terms of Service",
    cookies: "Cookie Policy",
    rights: "All rights reserved."
  },

  nav: {
    chain: "Chain Dashboard",
    founders: "For Builders",
    ownership: "New Social Contract",
    constitution: "Constitution",
    pricing: "Pricing",
    dashboard: "Dashboard",
    myProfile: "My Profile",
    settings: "Settings",
    home: "Home",
    ctrlaiCom: "CtrlAI.com",
    ctrlaiAi: "CtrlAI.ai",
    apply: "Apply as a Founder",
    editProfile: "Edit Profile",
    viewProfile: "View Profile",
    signOut: "Sign Out",
    about: "About Ctrl AI",
    logout: "Log out",
    members: "Members",
  },

  auth: {
    login: {
      title: "Welcome Back",
      subtitle: "Continue your journey in building the future",
      emailPlaceholder: "Enter your email",
      passwordPlaceholder: "Enter your password",
      submitButton: "Sign In",
      noAccount: "Don't have an account?",
      signupLink: "Sign up as a Founder",
      error: "Invalid email or password"
    },
    signup: {
      title: "Join as a Founder",
      subtitle: "Start building the future of technology",
      namePlaceholder: "Enter your full name",
      emailPlaceholder: "Enter your email",
      passwordPlaceholder: "Choose a password",
      submitButton: "Create Account",
      hasAccount: "Already have an account?",
      loginLink: "Sign in",
      error: "Error creating account"
    },
    errors: {
      passwordLength: "Password must be at least 6 characters long",
      invalidEmail: "Please enter a valid email address",
      emailInUse: "This email is already in use"
    },
    passwordRequirements: "Password must be at least 6 characters long",
    joinForm: {
      coverOperationalCosts: "Support our operations (recommended) - Your $1 will help cover operational costs instead of going to the treasury",
      processing: "Processing...",
      signUpWithEmail: "Sign up with Email",
      headline: "Join the Movement",
      continueWithGoogle: "Continue with Google",
      errors: {
        allFieldsRequired: "All fields are required",
        acceptTerms: "You must accept the terms to continue",
        passwordLength: "Password must be at least 6 characters",
        paymentFailed: "Payment process failed. Please try again."
      },
      subtitle: "Create your account to verify your humanity",
      costAllocationTitle: "Choose where your verification fee goes",
      operationalCosts: "Support Operations",
      operationalCostsDetail: "Help cover our computing and operational costs ($0.66 after payment processing fees)",
      treasuryCosts: "Keep it Untouched",
      treasuryCostsDetail: "We won't touch a penny! Your entire contribution (minus payment fees) goes straight to the Movement Treasury",
      orDivider: "or",
      email: "Email",
      emailPlaceholder: "Enter your email address",
      fullName: "Full Name",
      fullNamePlaceholder: "Enter your full name",
      password: "Password",
      passwordPlaceholder: "Choose a password (min. 6 characters)",
      alreadyHaveAccount: "Already have an account?",
      signIn: "Sign in",
      termsAgreement: "I agree to the",
      termsLink: "Terms of Service"
    }
  },

  members: {
    title: "Join Our Growing Movement",
    subtitle: "We're just getting started, and that's what makes it exciting. Join today to shape our community from the ground up and make your voice heard in AI's future.",
    stats: {
      membersLabel: "Verified Members",
      countriesLabel: "Countries",
      fundsLabel: "Total Funds Collected",
      joinHeading: "Join Our Growing Movement",
      joinDescription: "Be part of a global community working together to shape the future of AI. Verify your humanity and join us today for just $1."
    },
    share: {
      title: "Help Grow Our Impact",
      subtitle: "It only takes one share to bring in the next crucial voice.",
      twitterText: "I just discovered @CtrlAI, a global movement of real humans shaping AI's future. Join us!",
      buttons: {
        twitter: "Share on Twitter",
        facebook: "Share on Facebook",
        copyLink: "Copy Link",
        linkCopied: "Link copied to clipboard!"
      }
    },
    shareInvite: {
      title: "Share Your Impact",
      bulletPoints: [
        "Every share brings us closer to true democratic AI governance",
        "Help grow our global community",
        "Spread the word about AI for humanity"
      ],
      twitterText: "I just joined @CtrlAI to help shape the future of AI. Join me!",
      shareButtons: {
        twitter: "Share on Twitter",
        facebook: "Share on Facebook",
        copyLink: "Copy Link",
        linkCopied: "Link copied!"
      },
      founderNote: "You're member #{{memberNumber}} of our movement. Visit your profile to customize your public presence."
    }
  },

  

  quotaMap: {
    stats: {
      totalQuota: "Total Global Quota",
      totalRegistered: "Total Registered",
      totalPopulation: "Total Population",
      countryCount: "Countries Represented",
      fulfillment: "Global Fulfillment"
    },
    tooltip: {
      country: "Country",
      population: "Population",
      quota: "Member Quota",
      registered: "Registered Members",
      fulfillment: "Quota Fulfillment"
    },
    legend: {
      title: "Quota Fulfillment",
      low: "Low",
      target: "Target",
      exceeded: "Exceeded"
    },
    controls: {
      reset: "Reset View",
      zoom: "Zoom",
      pan: "Pan"
    },
    noData: "No data available for this country",
    loading: "Loading map data..."
  },

  profile: {
    memberNumber: 'Member #{{number}}',
    about: 'About',
    links: 'Links',
    notFound: 'Profile not found',
    error: 'Error loading profile',
    editTitle: 'Edit Profile',
    viewTitle: 'View Profile',
    photo: 'Profile Photo',
    displayName: 'Display Name',
    city: 'City',
    country: 'Country',
    bio: 'Bio',
    updateSuccess: 'Profile updated successfully',
    viewFullProfile: "View Full Profile",
    updateError: 'Error updating profile'
  },

  common: {
    saving: 'Saving...',
    save: 'Save Changes'
  },

}
